@font-face {
    font-family: 'Quicksand';
    src: url('~fonts/Quicksand-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Quicksand';
    src: url('~fonts/Quicksand-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Quicksand';
    src: url('~fonts/Quicksand-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Quicksand';
    src: url('~fonts/Quicksand-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Quicksand';
    src: url('~fonts/Quicksand-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

$font-family: Quicksand, Helvetica, sans-serif;