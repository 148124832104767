@use "./clarity";
@use "./fonts";
$mainColor: var(--cds-global-color-pink-700);
$tintColor: var(--cds-global-color-pink-50);
$shadeColor: var(--cds-global-color-pink-800);
$breakpoint: 768px;
:root {
  --cds-global-scale-space: 1.4;
  --cds-global-scale-layout-space: 1.4;
  --cds-global-scale-typography: 1.2;
  --cds-alias-status-info: #{$mainColor};
  --cds-alias-status-info-tint: #{$tintColor};
  --cds-alias-status-info-shade: #{$shadeColor};
  --cds-alias-border-color: #{$mainColor};
  --cds-alias-border-color-tint: #{$tintColor};
  --cds-alias-border-color-shade: #{$shadeColor};
  --cds-alias-object-container-background: var(--cds-global-color-tan-50);
  --cds-alias-object-container-border-color: #{$mainColor};
  --cds-global-typography-font-family: #{fonts.$font-family};
  --cds-global-typography-header-font-family: #{fonts.$font-family};
  --cds-alias-object-border-radius-200: 32px;
}
[cds-text~=link] {
  cursor: pointer;
}
cds-alert-group[type="default"][status=info] {
  --background: var(--cds-global-color-blue-50);
  --border-color: var(--cds-global-color-blue-700),
}
.hidden {
  display: none!important;
}
.disabled {
  opacity: .5!important;
  cursor: not-allowed!important;
}
.green {
  color: green;
}
img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
a {
  color: unset;
  text-decoration: none;
}
strong {
  font-weight: 600;
}
datalist {
  font-family: inherit;
}
html {
  height: 100%;
}
body {
  height: 100%;
  font-family: var(--cds-global-typography-font-family);
}
#layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  #layout-header {
    display: grid;
    padding: var(--cds-global-layout-space-md);
    row-gap: var(--cds-global-layout-space-xxxs);
    column-gap: var(--cds-global-layout-space-md);
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
            "logo brand menu"
            "logo slogan menu"
    ;
    @media screen and (max-width: $breakpoint) {
      column-gap: var(--cds-global-layout-space-xs);
      padding: var(--cds-global-layout-space-xs);
    }
    #layout-header-logo {
      grid-area: logo;
    }
    #layout-header-brand {
      grid-area: brand;
      color: $mainColor;
      align-self: end;
      margin: 0;
      font-weight: 400;
      font-size: 1.3rem;
      @media screen and (max-width: $breakpoint) {
        font-size: 1.1rem;
      }
    }
    #layout-header-slogan {
      grid-area: slogan;
      align-self: start;
      margin: 0;
      font-weight: 300;
      font-size: 1.1rem;
      @media screen and (max-width: $breakpoint) {
        font-size: 0.9rem;
      }
    }
    #layout-header-menu {
      grid-area: menu;
      display: flex;
      align-items: center;
      gap: var(--cds-global-layout-space-md);
      #layout-nav-trigger {
        display: none;
      }
      @media screen and (max-width: $breakpoint) {
        #layout-nav-trigger {
          display: flex;
        }
        & > :not(#layout-nav-trigger) {
          display: none;
        }
      }
    }
    .header-icon {
      padding: var(--cds-global-layout-space-xxs);
      background: #fff;
      border: solid 1px var(--cds-alias-border-color);
      border-radius: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    cds-icon {
      --color: var(--cds-alias-border-color);
      cursor: pointer;
    }
    a[cds-text] {
      text-decoration: none;
      display: inline-block;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: $mainColor;
        transform-origin: bottom left;
        transition: transform var(--cds-global-animation-duration-quick) ease;
      }
      &:hover:after {
        transform: scaleX(1);
      }
    }
  }
  #layout-nav {
    width: 100%;
    display: none;
    a {
      text-decoration: none;
      color: #000;
    }
    .nav-container, &:not(.nav-inited) {
      display: flex;
      gap: 12px;
      padding: 0 16px;
      flex-wrap: wrap;
      & > a {
        padding: 8px 16px;
        cursor: pointer;
        &.nav-tab-active, &:hover {
          border-bottom: solid 2px #ccc;
        }
      }
    }
    @media screen and (max-width: $breakpoint) {
      display: block;
      background: #fff;
      position: sticky;
      border-bottom: solid 1px #ccc;
      z-index: 1000;
      &:not(.nav-inited) > .nav-tab-active {
        padding: 0 !important;
        border: none !important;
      }
      &:not(.nav-inited) > :not(.nav-tab-active) {
        display: none;
      }
      &:not(.nav-inited) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        cursor: pointer;
        .nav-header-toggle {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
      .nav-container {
        flex-wrap: wrap;
        gap: 0;
        padding: 0;
        background: #fff;
        border-bottom: solid 1px #ccc;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;
        & > a {
          flex: 0 0 100%;
          padding: var(--cds-global-layout-space-md) var(--cds-global-layout-space-sm);
          border-top: solid 1px #ccc;
          border-bottom: none !important;
          &.nav-tab-active {
            display: none;
          }
        }
      }
      &:not(.nav-expanded) {
        .nav-container {
          display: none;
        }
      }
      &.nav-expanded {
        .nav-backdrop {
          display: block;
          position: fixed;
          background: #66666666;
          top: 122px;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }
      }
    }
  }
  &.has-subheader {
    #layout-nav {
      border-bottom: none;
    }
  }
  &:not(.has-subheader){
    #layout-container {
      padding: var(--cds-global-layout-space-xs);
    }
  }
  #layout-container {
    flex-grow: 1;
    overflow: auto;
    display: grid;
    flex-direction: column;
    .layout-content {
      background: var(--cds-alias-object-container-background);
      border-radius: var(--cds-alias-object-border-radius-200);
      align-self: center;
      justify-self: center;
      max-width: var(--cds-global-layout-width-sm);
    }
  }
  #layout-footer {
    flex-shrink: 0;
    background: var(--cds-alias-object-container-background);
    border-top: solid var(--cds-alias-object-border-width-100) var(--cds-alias-object-container-border-color);
    position: sticky;
    bottom: 0;
    z-index: 100;
    .footer-icon {
      padding: var(--cds-global-layout-space-xxs);
      background: #fff;
      border: solid 1px var(--cds-alias-border-color);
      border-radius: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}